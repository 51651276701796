import React from "react"
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import { BiPhone, BiMailSend } from 'react-icons/bi'
import { FaSignature, FaMoneyCheck } from 'react-icons/fa'
import { BsFillAwardFill } from 'react-icons/bs'
import { RiServiceFill } from 'react-icons/ri'
import { AiOutlineCheckCircle, AiOutlineTranslation } from 'react-icons/ai'
import { StaticImage } from 'gatsby-plugin-image'
import * as styles from '../styles/home.module.css'

export default function Home() {
  return (
    <Layout>
      <section className={styles.home}>
        <div className="u-center-text u-margin-bottom-big">
            <h2 className="heading-secondary">
              Fast Apostille Services
            </h2>
        </div>
        <div className="row">
          <div className="col-1-of-2">
            <h3 className="heading-tertiary u-margin-bottom-small">
              Need an Apostille? 
            </h3>
            <p className="paragraph">
              We provide hassle free super-fast Apostille applications via the FCO. We have various turnaround options including the standard 2 weeks, an urgent 3-5 business days service, and also a super-fast next day Apostille service.
            </p>
            <p className="paragraph">
              There is a plethora of documents that need an Apostille for countless reasons. 
              It is important that your documents are presented in the right way. Our professional service is designed to make your experience as simple as possible.
            </p>
          </div>
          <div className="col-1-of-2">
            <div className={styles.home__contact}>
              <a href="tel:+448453889263">
                  <BiPhone />
                      0845 388 9263
              </a>
              <a href="mailto:info@apostille-express.co.uk">
                  <BiMailSend />
                    info@apostille-express.co.uk
              </a>
            </div>
          </div>
        </div>
        <div className="seperator"></div>
        <div className="row">
          <div className="col-1-of-2">
            <h3 className="heading-tertiary u-margin-bottom-small">Apostilles for all</h3>
            <p className="paragraph">
              Education Certificates, Legalising Degrees, Diplomas, A Levels <br/>
              <span className="text-muted"> and other qualifications.</span>
            </p>
            <div className={styles.image_container}>
              <StaticImage
                src="../images/diploma.png"
                alt="Apostilles for all education certificates"
                placeholder="blurred"
                layout="fullWidth"
              />
            </div>
          </div>
          <div className="col-1-of-2">
            <p className="paragraph">
              Apostille Express has been designed to help you, our customer, to process your <Link to="/documents">documents</Link> hassle free. 
              Our service has been specifically designed for both private individuals and companies who need to present their documents abroad.
            </p>
            <p className="paragraph">
              If the document you are presenting in a <Link to="/countries">country</Link> which has signed the Hague Convention (and therefore requires an Apostille) is in another language other than the one of the country you are presenting this document, then you need to have this document translated. Our Head Office can help you translate your documents in over 250 languages and can help you process your documents for Apostille. It is also referred to as legalisation.
            </p>
            <p className="paragraph">
            If this is the first time you came across this request, no need to panic. We can explain the process to you over the telephone or over the email. You can call us with as many questions and we shall happily try and help.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-1-of-2  reverse">
            <p className="paragraph">
              The Apostille Certificate or legalisation can be used for work, business, legal issues and personal matters, schools, export, tender documents, etc.
            </p>
            <p className="paragraph">
              The popular in terms of requests are overseas marriage certificates or UK marriage certificates which need to be presented overseas, documents requested by the Embassies in the UK, birth certificates, foreign property purchases, business transactions, contracts, Companies House certificates.
            </p>
            <p className="paragraph">
              We can provide Apostille Certificates for almost all genuine documents issued in the UK.
            </p>
            <p className="paragraph">
              We have listed the most requested documents on Apostille <Link to="/documents">documents page</Link> for legalisation, however this list is hardly non-exhaustive. Therefore if you need information or you think your document is not listed please <Link to="/contact">contact us</Link>.
            </p>
          </div>
          <div className="col-1-of-2">
            <div className={styles.image_container}>
                <StaticImage
                  src="../images/gold-stamp.png"
                  alt="Apostilles for all education certificates"
                  placeholder="blurred"
                  layout="fullWidth"
                />
              </div>
          </div>
        </div>
        <div className={styles.features}>
          <div className="row">
            <div className="col-1-of-4">
              <div className={styles.box}>
                <BsFillAwardFill className={styles.box__icon}/>
                <p>
                  We offer a professional document legalisation service for private individuals and organisations that need to use UK documents overseas.
                </p>
              </div>
            </div>
            <div className="col-1-of-4">
              <div className={styles.box}>
                <RiServiceFill className={styles.box__icon}/>
                <p>
                  We have been assisting people with translation and legalisation since 2002.
                </p>
              </div>
            </div>
            <div className="col-1-of-4">
              <div className={styles.box}>
                <FaSignature className={styles.box__icon}/>
                <p>
                  Our signature and stamps are recognised by the Foreign and Commonwealth Office.
                </p>
              </div>
            </div>
            <div className="col-1-of-4">
              <div className={styles.box}>
                <AiOutlineCheckCircle className={styles.box__icon}/>
                <p>
                  We always keep and record all documents processed and we always ensure all documents processed by us are correctly done.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="u-center-text u-margin-bottom-big">
            <h2 className="heading-secondary">
                What do we need from you?
            </h2>
        </div>
        <div className="row">
          <div className="col-1-of-2">
            <p className="paragraph">
              1. If you require a legalisation stamp (Apostille) on your original <Link to="/documents">document</Link> on a copy of this document  AND you do not require a translation service, please send us your original document.
            </p>
            <p className="paragraph">
              2.  A copy (sent by email, fax, etc) of the <Link to="/documents">document</Link> if you only require the translation to have an Apostille.
              Once we have received your documents we shall send you a written quotation. If you accept your quotation then you need to decide which method of payment you prefer. You can choose from: Bank transfer, online payment, payment with credit/debit card over the telephone (no extra or hidden charges).
            </p>
          </div>
          <div className="col-1-of-2">
            <div className={styles.home__service}>
              <Link to="/services">
                <AiOutlineTranslation />
                Other services and translation services for Apostille
              </Link>
              <Link to="/cost">
                <FaMoneyCheck />
                Costs and procedures
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
